import Vue from 'vue';
import Vuex from 'vuex';
import { io } from 'socket.io-client';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        empresa: {},
        usuario: {},
        socket: null,
    },

    getters: {
        getEmpresa: state => {
            return state.empresa;
        },

        getUsuario: state => {
            return state.usuario;
        },

        getToken: state => {
            return state.usuario.token;
        },
    },

    mutations: {
        setEmpresa(state, payload) {
            state.empresa = payload;
        },

        setUsuario(state, payload) {
            state.usuario = payload;
        },
    },

    actions: {
        setUpWs({ state: st }) {
            const { id } = st.usuario;

            const isLocalHost = document.URL.indexOf('localhost') !== -1;

            if (isLocalHost) {
                // return;
            }

            if (st.socket || !id) {
                return;
            }

            st.socket = isLocalHost ? io('http://localhost:3000') : io();

            // st.socket.connected

            st.socket.on('connect', () => {
                id && st.socket.emit('connectZaperControlUserId', id);
            });

            st.socket.on('chat_event', payload => {
                document.dispatchEvent(new CustomEvent('chat-event', { detail: payload }));
            });
        },
    },

    modules: {
        //
    },
});
