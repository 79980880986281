import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import moment from 'moment';
import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask';

import './api';
import './registerServiceWorker';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.config.productionTip = false;

Vue.use(vuetify, { iconfont: 'mdi' });

Vue.use(VueTheMask);

Vue.mixin({
    methods: {
        notify(text, type = 'success') {
            setTimeout(() => this.$root.$emit('notify', {text, type}), 100);
        },

        formatPrice(value) {
            let val = (+value).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },

        formatDate(dh, format = 'DD/MM/YYYY HH:mm') {
            return dh ? this.moment(dh).format(format) : '';
        },

        checkPermission(permission) {
            const userPermissions = store.state.usuario.permissions?.map(e => e.resource);
            return userPermissions?.includes(permission);
        },
    },
});

Vue.prototype.moment = moment;

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app');
